<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="productPrice" label="原价" prop="productPrice">
        <a-input-number
          v-model="form.productPrice"
          style="width: 200px"
          placeholder="请输入原价(金额)"
          :precision="2"
          :max="99999"
          :min="0"
        /> 元
      </a-form-model-item>
      <a-form-model-item ref="name" label="库存" prop="sumStock">
        <a-input-number
          v-model="form.sumStock"
          style="width: 200px"
          placeholder="请输入库存"
          :precision="0"
          :max="9999999"
          :min="1"
        />
      </a-form-model-item>
      <a-form-model-item ref="specImg" label="规格图片" prop="specImg">
        <a-upload
          :action="IMG_API + '/oss/files'"
          list-type="picture-card"
          :file-list="specImgList"
          @preview="handlePreview"
          @change="handleChange"
          :before-upload="beforeUpload"
        >
          <div v-if="specImgList.length < 5">
            <a-icon type="plus" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <span>建议上传小于1MB的PNG、GIF或JPG图片，最多5张，图片尺寸为1：1</span>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-model-item>
      <a-form-model-item ref="payTypes" label="兑换方式" prop="payTypes">
        <a-select
          v-model="form.payTypes"
          mode="multiple"
          style="width: 300px"
        >
          <a-select-option :value="0">仅积分</a-select-option>
          <a-select-option :value="1">组合支付（积分+现金）</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      仅积分-->
      <div v-if="form.payTypes && form.payTypes.includes(0)">
        <a-form-model-item ref="integralValue" label="仅积分" prop="integralValue">
          <a-input-number
            v-model="form.integralValue"
            style="width: 200px"
            placeholder="请输入所需积分值"
            :precision="0"
            :min="1"
            :max="9999999999"
          />
        </a-form-model-item>
      </div>
      <div v-if="form.payTypes && form.payTypes.includes(1)">
        <div style="font-weight: 600; margin-left: 80px"><span style="color:red">* </span>组合支付:</div>
        <a-form-model-item ref="merchantId" label="关联商户编号" prop="merchantId">
          <a-select
            show-search
            style="width: 360px"
            placeholder="请选择"
            v-model="form.merchantId"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          >
            <a-select-option
              v-for="option in merchantsOptions"
              :key="option.value"
              :value="option.value"
            >{{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="integralFee" label="积分" prop="integralFee">
          <a-input-number
            v-model="form.integralFee"
            style="width: 200px"
            placeholder="请输入所需积分值"
            :precision="0"
            :min="1"
            :max="9999999999"
          />
        </a-form-model-item>
        <a-form-model-item ref="cashFee" label="现金" prop="cashFee">
          <a-input-number
            v-model="form.cashFee"
            style="width: 200px"
            placeholder="请输入"
            :precision="2"
            :min="0"
            :max="99999999"
          /> 元
        </a-form-model-item>
      </div>
      <a-form-model-item ref="specSale" label="是否在售" prop="specSale">
        <a-radio-group
          v-model="form.specSale"
        >
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="cancel">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import {IMG_API} from "@/config";

  export default {
    name: 'ExchangeRuleSettings',
    computed: {
      IMG_API() {
        return IMG_API
      }
    },
    props: ['currentEditSpecData', 'merchantsOptions'],
    data() {
      return {
        // 暂存规格图片
        specImgList: [],
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        // 是否预览
        previewVisible: false,
        // 预览的图片地址
        previewImage: "",
        form: {
          // 原价
          productPrice: '',
          // 库存
          sumStock: '',
          // 规格图片
          specImg: [],
          // 兑换方式
          payTypes: undefined,
          // 积分值
          integralValue: '',
          // 组合支付时的积分值
          integralFee: '',
          // 现金值
          cashFee: '',
          // 是否在售， 1: 是 0: 否
          specSale: 1
        },
        rules: {
          productPrice: [
            { required: true, message: '请输入原价', trigger: 'blur' },
          ],
          sumStock: [
            { required: true, message: '请输入库存', trigger: 'blur' },
          ],
          specImg: [
            { required: true, message: '请上传规格图片', trigger: 'change' }
          ],
          payTypes: [
            { required: true, message: '请选择兑换方式', trigger: 'change' }
          ],
          merchantId: [
            { required: true, message: '请选择关联商户编号', trigger: 'change' }
          ],
          // 仅积分的积分值
          integralValue: [
            { required: true, message: '请输入所需积分值', trigger: 'blur' },
          ],
          // 组合支付的积分值
          integralFee: [
            { required: true, message: '请输入所需积分值', trigger: 'blur' },
          ],
          cashFee: [
            { required: true, message: '请输入所需现金值', trigger: 'blur' }
          ],
          specSale: [
            { required: true, message: '请选择是否在售', trigger: 'change' }
          ]
        },
      }
    },
    watch: {
      currentEditSpecData: {
        handler(val) {
          this.specImgList = []
          if(val) {
            console.log(val, '这是传过来的数据')
            this.form = {
              productPrice: '',
              sumStock: '',
              specImg: [],
              payTypes: undefined,
              integralValue: '',
              cashFee: '',
              specSale: 1,
              ...val
            }
            if(val.specImg && val.specImg.length) {
              this.specImgList = val.specImg.map((item, index) => {
                return {
                  uid: index,
                  name: 'image.png',
                  status: 'done',
                  url: item || ''
                }
              })
            }
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      /**
       * 提交数据
       */
      onSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            // 把数据传回父组件
            let newForm = JSON.parse(JSON.stringify(this.form))
            if (!newForm.payTypes.includes(0)) {
              newForm.integralValue = ''
            }
            if (!newForm.payTypes.includes(1)) {
              newForm.cashFee = ''
              newForm.integralFee = ''
            }
            this.$emit('submitSpecConfig', newForm)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      /**
       * 取消
       */
      cancel() {
        this.$refs.ruleForm.resetFields();
        this.$emit('closeModal')
      },
      /**
       * 预览图片
       */
      handlePreview (file) {
        this.previewImage = file.url ? file.url : file.response.redirect_uri;
        this.previewVisible = true;
      },
      /**
       * 上传图片
       */
      handleChange ({ file, fileList }) {
        this.specImgList = fileList;
        if (fileList.length && fileList[0].status === 'done') {
          let imgList = []
          fileList.forEach(item => {
            imgList.push(item.url ? item.url : item.response.redirect_uri)
          })
          this.form.specImg = imgList
        }
        if (fileList.length ===  0) {
          this.form.specImg = []
        }
      },
      /**
       * 上传前的校验
       */
      beforeUpload (file) {
        // 校验格式
        const isJpgOrPng = file.type === "image/png"
          || file.type === "image/jpg"
          || file.type === "image/gif"
          || file.type === 'image/jpeg';
        // 校验大小
        const isLt1M = file.size / 1024 / 1024 <= 1;
        if (!isJpgOrPng) {
          this.$message.error("请上传jpg、gif或png格式");
          return Promise.reject();
        }
        if(!isLt1M) {
          this.$message.error("图片最大不能超过1MB");
          return Promise.reject();
        }
        return isJpgOrPng && isLt1M;
      },
      /**
       * 关闭图片预览
       */
      handleCancel () {
        this.previewVisible = false;
      },

    }
  }

</script>

<style scoped lang="scss">

</style>
