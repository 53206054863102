<template>
  <div>
    <div style="text-align: right">
      <a-button type="primary" @click="addRow" v-show="operationType !== 'view'">添加</a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      bordered
      :pagination="false"
      :columns="columns"
      :data-source="tableData"
    >
      <template slot="code" slot-scope="text, record, index">
        <a-input
          v-if="record.editable"
          style="margin: -5px 0"
          :value="text"
          @change="e => handleChange(e.target.value, record.index, 'code')"
        />
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template slot="startTime" slot-scope="text, record, index">
        <a-date-picker
          v-if="record.editable"
          style="margin: -5px 0"
          v-model="text"
          format="YYYY-MM-DD HH:mm:ss"
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          @change="e => handleChange(e, record.index, 'startTime')"
        />
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template slot="endTime" slot-scope="text, record, index">
        <a-date-picker
          v-if="record.editable"
          style="margin: -5px 0"
          v-model="text"
          format="YYYY-MM-DD HH:mm:ss"
          :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          @change="e => handleChange(e, record.index, 'endTime')"
        />
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="() => save(record.index)">保存</a>
          <a-popconfirm title="确定取消吗?" @confirm="() => cancel(record.index)">
            <a>取消</a>
          </a-popconfirm>
        </span>
          <span v-else>
          <a :disabled="editingKey !== '' || operationType === 'view' || record.status === 1 || record.status === 2" @click="() => edit(record.index)">编辑</a>
        </span>
        </div>
        <div class="editable-row-operations">
        <a :disabled="editingKey !== '' || operationType === 'view' || record.status === 1 || record.status === 2" @click="deleteRow(record.index)">删除</a>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'CodeTable',
  props: {
    codeList: {
      required: true,
      type: Array
    },
    operationType: {
      required: true,
      type: String
    }
  },
  watch: {
    codeList: {
      handler(val) {
        if(val && val.length) {
          const newData = val.map((item, index) => {
            return {
              ...item,
              index: index + 1
            }
          })
          this.tableData = newData
          this.cacheData = JSON.parse(JSON.stringify(newData))
        }
      },
      immediate: true,
      deep: true
    },
    operationType: {
      handler(val) {
        if(val && val !== 'view') {
          const newData = [ this.columns ]
          this.columns.splice(this.columns.length - 1, 1)
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      moment,
      cacheData: [],
      tableData: [],
      editingKey: '',
      columns: [
        {
          title: '编号',
          dataIndex: 'index',
        },
        {
          title: '串码',
          dataIndex: 'code',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
        {
          title: '是否已兑换',
          dataIndex: 'status',
          customRender(text) {
            return text ? '是' : '否'
          }
        }
      ]
    }
  },
  methods: {
    // 将处理完的数据传回父组件
    setCodeList() {
      this.$emit('setCodeData', this.tableData)
    },
    // 删除
    deleteRow(key) {
      const newData = [...this.tableData];
      const newCacheData = [...this.cacheData];
      const targetIndex = newData.findIndex(item => key === item.index);
      const targetCacheIndex = newCacheData.findIndex(item => key === item.index);
      newData.splice(targetIndex, 1)
      newCacheData.splice(targetCacheIndex, 1)
      this.tableData = newData
      this.cacheData = newCacheData
      this.setCodeList()
    },
    // 添加
    addRow() {
      const obj = {
        index: this.tableData.length,
        code: '',
        startTime: '',
        endTime: ''
      }
      this.tableData.push(obj)
      this.cacheData.push(obj)
      this.setCodeList()
    },
    // 编辑
    edit(key) {
      const newData = [...this.tableData];
      const target = newData.find(item => key === item.index);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.tableData = newData;
      }
    },
    // 保存
    save(key) {
      const newData = [...this.tableData];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.index);
      const targetCache = newCacheData.find(item => key === item.index);
      if (target && targetCache) {
        delete target.editable;
        this.tableData = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
      this.setCodeList()
    },
    // change事件
    handleChange(value, key, column) {
      const newData = [...this.tableData];
      const target = newData.find(item => key === item.index);
      if (target) {
        target[column] = column !== 'code' ? (value ?  moment(value).format('YYYY-MM-DD HH:mm:ss') : '') : value;
        this.tableData = newData;
      }
    },
    // 取消保存
    cancel(key) {
      const newData = [...this.tableData];
      const target = newData.find(item => key === item.index);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.index));
        delete target.editable;
        this.tableData = newData;
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>


