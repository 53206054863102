<template>
  <div id="addModify">
    <div class="headerDetail">
      <ds-header :title="`积分商品管理 / ${ operationType === 'view' ? '查看': operationType === 'edit' ? '修改' : '创建' }自营商品`"></ds-header>
      <a-button type="primary" @click="goback">取消</a-button>
    </div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      labelAlign="right"
      :labelCol="{ style: 'width: 120px' }"
      :rules="rules">
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>商品类型</div>
        <div class="content">
          <a-form-model-item label="商品类型：" prop="productType">
            <a-radio-group :disabled="disabled || operationType === 'edit'" v-model="form.productType" @change="handleType">
              <a-radio
                v-for="item in productTypeOptions"
                :key="item.commonsValue"
                :value="item.commonsValue">{{item.commonsValueName}}</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>基本信息</div>
        <div class="content">
          <a-form-model-item label="商品名称：" prop="productName">
            <a-input :maxLength="50" :disabled="disabled" style="width: 200px;" placeholder="请输入商品名称" v-model="form.productName" />
          </a-form-model-item>
          <a-form-model-item label="商品标签：" prop="productLabel">
            <a-input :maxLength="6" :disabled="disabled" style="width: 200px;" v-model="form.productLabel" placeholder="请输入商品标签" />
          </a-form-model-item>
          <a-form-model-item label="封面图片" prop="productCoverImg">
            <a-upload
                :action="IMG_API + '/oss/files'"
                list-type="picture-card"
                :file-list="productCoverPhone"
                @preview="handlePreviewCoverPhoto"
                @change="handleCoverPhotoChange"
                :before-upload="beforeCoverPhotoUpload"
                :disabled="disabled"
            >
              <div v-if="productCoverPhone.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
            <span>建议上传小于1MB的PNG、GIF或JPG图片，图片尺寸1：1</span>
            <a-modal
                :maskStyle="{padding: '10px'}"
                :visible="previewCoverPhotoVisible" :footer="null" @cancel="handleCancelCoverPhotoPreview">
              <img alt="example" style="width: 100%" :src="previewCoverPhoto" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item label="是否短信验证：" prop="needSmsValid">
            <a-radio-group
              v-model="form.needSmsValid"
              :disabled="disabled"
              :default-value="0">
              <a-radio :value="0">否</a-radio>
              <a-radio :value="1">是</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px"  v-if="form.productType === '1'">
        <div class="title"><span style="color:red">* </span>
          券活动ID
        </div>
        <div class="content">
          <a-form-model-item label="券活动ID" prop="activeId">
            <a-input ref="activeId"
                     :disabled="disabled || operationType === 'edit'"
                     v-model="form.activeId"
                     placeholder="请填写对应的活动ID"
                     @blur="blurActiveId(form.activeId)"
                     style="width: 200px" />
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px" v-if="form.productType === '0'">
        <div class="title"><span style="color:red">* </span>
          配送类型
        </div>
        <div class="content">
          <a-form-model-item>
            <a-radio-group
              v-model="form.distributionType"
              :default-value="0"
              :disabled="disabled"
            >
              <a-radio :value="0">自提</a-radio>
              <a-radio :value="1" disabled>配送</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="pickAddressList">
            <div v-for="(item, index) in pickAddressList" :key="index + ''">
              <a-select
                v-model="item.pickAddressFirstLevel"
                :disabled="disabled"
                style="width: 200px;margin:0 15px"
              >
                <a-select-option v-for="(arr, index) in plainOptions" :key="arr.value">
                  {{ arr.label }}
                </a-select-option>
              </a-select>
              <a-input :maxLength="50"
                       v-model="item.pickAddressSecondLevel"
                       :disabled="disabled"
                       @blur="blurPickAddressList"
                       :placeholder="`请添加${form.distributionType === 0 ? '自提' : '配送'}详细地址`"
                       style="width: 200px;margin:0 15px" />
              <a-button type="primary" style="marginLeft:15px" v-if="!disabled" @click="addRegion">
                <a-icon type="plus" />
              </a-button>
              <a-button v-if="index !== 0" v-show="!disabled" style="marginLeft:15px" type="danger" @click="delRegion(index)">
                <a-icon type="line" />
              </a-button>
            </div>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>价格库存</div>
        <div class="content">
          <a-form-model-item label="规格类型" prop="specType">
            <a-radio-group
              v-model="form.specType"
              :disabled="disabled"
            >
              <a-radio :value="0">单规格</a-radio>
              <a-radio :value="1" :disabled="form.productType === '1' || form.productType ==='2'">多规格</a-radio>
            </a-radio-group>
          </a-form-model-item>

            <a-form-model
              ref="specForm"
              :model="specForm"
              labelAlign="right"
              :labelCol="{ style: 'width: 120px' }"
              :rules="specFormRules"
            >
              <!--单规格-->
              <div v-if="form.specType === 0">
              <a-form-model-item label="原价" prop="productPrice">
                <a-input-number :disabled="disabled" :precision="2" placeholder="请输入原价(金额)" v-model="specForm.productPrice" style="width: 200px" :max="99999" :min="0" />元
              </a-form-model-item>

              <a-form-model-item label="库存" prop="sumStock" style="margin: 10px 0">
                <a-input-number v-if="form.productType !== '2'" :precision="0" :disabled="disabled" placeholder="请输入库存" v-model="specForm.sumStock" style="width: 200px" :max="9999999" :min="1" />
               <div v-else>
                 <div>
                   <span style="margin-right: 20px">{{ codeList.length }}</span>
                   <span>
                      <a-upload
                        name="file"
                        :multiple="true"
                        :action="`/api/dscloud-member-mall/web/v2/product/file/thirdCode?productId=${form.id}`"
                        accept=".xlsx"
                        :headers="upload.headers"
                        :showUploadList="false"
                        @change="handleChangeFile"
                      >
                       <div v-if="!disabled">
                          <a-button type="primary"> <a-icon type="upload"  /> 上传 </a-button>
                          <a-button type="link" style="margin-left: 15px" @click="downloadTemplate" >下载模板</a-button>
                       </div>
                      </a-upload>
                   </span>
                 </div>
               </div>
              </a-form-model-item>
              <a-form-model-item v-show="form.productType === '2'" style="margin-bottom: 10px">
                <CodeTable :operationType="operationType" @setCodeData="setCodeData" :codeList="codeList" />
              </a-form-model-item>
              <a-form-model-item label="商品图片：" prop="specImg">
                <a-upload
                  :disabled="disabled"
                  :action="IMG_API + '/oss/files'"
                  list-type="picture-card"
                  :file-list="specImgList"
                  @preview="handlePreview"
                  @change="handleChange"
                  :before-upload="beforeUpload"
                >
                  <div v-if="specImgList.length < 5">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <span>建议上传小于1MB的PNG、GIF或JPG图片，最多5张，图片尺寸为1：1，默认第一张为头图</span>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </a-form-model-item>
              <a-form-model-item v-if="form.productType === '2'" label="使用说明" class="userLabel">
                <div id="editor"></div>
              </a-form-model-item>
              <a-form-model-item v-if="form.productType === '2'" label="使用地点">
                <a-input style="width: 300px" :disabled="disabled" :maxLength="25" v-model="form.useLocation" />
              </a-form-model-item>
              <div style="font-weight: 600; font-size: 14px; margin-left: 40px">兑换规则</div>
              <a-form-model-item label="兑换方式" prop="payTypes">
                <a-select
                  :disabled="disabled"
                  mode="multiple"
                  style="width: 300px"
                  placeholder="请选择"
                  v-model="specForm.payTypes"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <a-select-option
                    v-for="option in exchangeMethodOptions"
                    :key="option.value"
                    :value="option.value"
                  >{{ option.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <div v-if="specForm.payTypes.includes(0)">
                <a-form-model-item label="仅积分" prop="integralValue">
                  <a-input-number :precision="0" :min="1" :max="9999999999" :disabled="disabled" placeholder="请输入所需积分值" v-model="specForm.integralValue" style="width: 200px" />
                </a-form-model-item>
              </div>
              <div v-if="specForm.payTypes.includes(1)">
                <div style="font-weight: 600; margin-left: 40px"><span style="color:red">* </span>组合支付:</div>
                <div style="margin-left: 80px">
                  <a-form-model-item label="关联商户编号" prop="merchantId">
                    <a-select
                      show-search
                      :disabled="disabled"
                      style="width: 300px"
                      placeholder="请选择关联商户"
                      v-model="specForm.merchantId"
                      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    >
                      <a-select-option
                        v-for="option in merchantsOptions"
                        :key="option.value"
                        :value="option.value"
                      >{{ option.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="积分" prop="integralFee">
                    <a-input-number :precision="0" :min="1" :max="9999999999" :disabled="disabled" placeholder="请输入所需积分值" v-model="specForm.integralFee" style="width: 200px" />
                  </a-form-model-item>
                  <a-form-model-item label="现金" prop="cashFee">
                    <a-input-number :precision="2" :min="0" :max="99999999" :disabled="disabled" placeholder="请输入所需现金值" v-model="specForm.cashFee" style="width: 200px" />元
                  </a-form-model-item>
                </div>
              </div>
              </div>

              <!--多规格-->
              <div v-if="form.specType === 1">
                <a-form-model-item label="规格设置" :required="false">
                  <a-form-model-item>
                    <a-button type="primary" :disabled="specifications.length >= 3 || disabled"   @click="addSpecifications">添加规格项</a-button>
                    <span style="color: red; margin-left: 10px">注：规格设置修改后会影响规则明细，原有数据会自动重置，请谨慎操作。</span>
                  </a-form-model-item>
                  <div class="specificationSettings" v-for="(item, index) in specifications" :key="index">
                    <a-form-model-item :label="`规格${index + 1}`">
                      <a-input placeholder="请输入规格名称" :disabled="disabled" @input="handleChangeSpecificationsName($event, index)" v-model.trim="item.specificationsName" style="width: 300px" :maxLength="10" />
                      <a-button type="primary" :disabled="disabled" @click="removeSpecifications(index)" icon="minus" style="margin-left: 10px"></a-button>
                    </a-form-model-item>
                    <a-form-model-item label="规格值">
                      <a-input ref="userNameInput" :disabled="disabled" @change="handleChangeSpecificationsValue($event, index, i)" :maxLength="8" v-model.trim="item.specificationsValueList[i]" style="width: 170px; margin-right: 10px" v-for="(v, i) in item.specificationsValueList" :key="i">
                        <a-tooltip slot="suffix">
                          <a-icon type="close-circle" @click="removeSpecificationsValue(index, i)"/>
                        </a-tooltip>
                      </a-input>
                      <a-button type="primary" :disabled="item.specificationsValueList.length >= 5 || disabled" icon="plus" @click="addSpecificationsValue(index)">
                        添加规格值
                      </a-button>
                    </a-form-model-item>
                  </div>
                </a-form-model-item>
                <div class="title" v-show="specifications.length"><span style="color:red">* </span>规格明细</div>
                <a-table
                  v-show="specifications.length"
                  :rowKey="(record, index) => index"
                  bordered
                  :pagination="false"
                  :columns="columns"
                  :data-source="specificationDatailsList"
                >
                  <template slot="specImg" slot-scope="text, record, index">
                    <img width="100px" height="100px" v-show="record.specImg && record.specImg.length" :src="record.specImg && record.specImg.length && record.specImg[0]" alt="">
                  </template>
                  <template slot="operation" slot-scope="text, record, index">
                    <a-button type="link" :disabled="disabled" @click="exchangeRuleSettings(record, index)" >兑换规则设置</a-button>
                  </template>
                </a-table>
              </div>
            </a-form-model>
          </div>
        </div>
      <div style="margin-top: 20px">
        <div class="title">商品详情</div>
        <div class="content">
          <div id="editer"></div>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title"><span style="color:red">* </span>归属项目</div>
        <div class="content">
          <a-form-model-item prop="projectList">
            <div>
              <a-checkbox :disabled="disabled" :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">全选</a-checkbox>
            </div>
            <a-checkbox-group :disabled="disabled" v-model="checkedList" :options="plainOptions" @change="onChange" />
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top: 20px">
        <div class="title">关联楼层</div>
        <div class="content">
          <a-form-item label="选择楼层：">
            <div v-for="(item, index) in form.floors">
              <a-select
                :disabled="disabled"
                style="width: 200px"
                :allowClear="index === 0"
                placeholder="请选择"
                v-model="form.floors[index]"
                @change="floorsChange($event, index)"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option
                  v-for="option in floorsList"
                  :key="option.id"
                  :value="option.id"
                  :disabled="!option.floorState"
                >{{ option.floorName }}
                </a-select-option>
              </a-select>
              <a-button type="primary" style="marginLeft:15px" :disabled="disabled" @click="addFloor">
                <a-icon type="plus" />
              </a-button>
              <a-button v-if="index !== 0" :disabled="disabled" type="danger" style="marginLeft:15px" @click="deleteFloor(index)">
                <a-icon type="line" />
              </a-button>
            </div>
          </a-form-item>
        </div>
      </div>
    </a-form-model>
    <div class="footer">
      <a-button
        v-show="!disabled"
        key="submit"
        style="margin-right: 30px"
        type="primary"
        @click="save"
      >保存
      </a-button>
    </div>
    <a-modal
      title="兑换规则设置"
      :visible="visible"
      :footer="null"
      width="40%"
      :closable="false"
      :destroyOnClose="true"
    >
      <ExchangeRuleSettings @submitSpecConfig="submitSpecConfig" :merchantsOptions="merchantsOptions" :currentEditSpecData="currentEditSpecData" @closeModal="closeModal"/>
    </a-modal>
  </div>
</template>

<script>
import wangEditor from 'wangeditor'
import { IMG_API } from "@/config";
import ExchangeRuleSettings from "../components/ExchangeRuleSettings.vue";
import XLSX from "xlsx";
import CodeTable from '../components/CodeTable.vue'
import {
  getProjectList,
  getProductList,
  getFloorList,
  addProduct,
  productDetail,
  getActive,
  getMerchants, updateProduct
} from '@/api/integralGoods'
import { projectName } from "../../../../api/pointsManagement"
import {mapState} from "vuex";
import moment from "moment";


export default {
  computed: {
    ...mapState({
      plainOptions: (state) => {
        return state.common.projectList && state.common.projectList.length && state.common.projectList.map((item) => {
          return {
            value: item.id,
            label: item.project_name
          }
        })
      },
    }),
  },
  components:{
    ExchangeRuleSettings,
    CodeTable
  },
  watch: {
    'form.productType': {
      handler(val) {
        if(val === '2') {
          this.$nextTick(() => {
            this.createEditor()
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    let validateUseDescription = (rule, value, callback) => {
      if (this.form.useDescription === '' || !this.form.useDescription) {
        callback(new Error('请输入使用说明'));
      } else {
        callback();
      }
    };
    let validateProjectList = (rule, value, callback) => {
      if (this.form.projectList.length === 0) {
        callback(new Error('请选择归属项目'));
      } else {
        callback();
      }
    };
    // 校验自提地址
    let validatePickAddressList = (rule, value, callback) => {
      if(!this.form.pickAddressList || this.form.pickAddressList.length === 0) {
        callback(new Error('输入自提地区和详细地址'));
      } else if (this.form.pickAddressList.some(item => !item.pickAddressFirstLevel || !item.pickAddressSecondLevel)){
        callback(new Error('输入自提地区和详细地址'));
      } else {
        callback()
      }
    }
    return {
      useLabel: '<span style="color: red;">*</span>',
      upload: {
        // 设置上传的请求头部
        headers: { Authorization: JSON.parse(localStorage.getItem("user")).accessToken },
      },
      // 串码list
      codeList: [],
      // 操作类型
      operationType: '',
      // 是否禁用
      disabled: false,
      // 当前查看的商品id
      currentProductId: '',
      // 当前编辑的兑换规则下标
      currentEditSpecIndex: 0,
      // 当前编辑的兑换规则
      currentEditSpecData: {},
      // 暂存商品封面图片，用于展示
      productCoverPhone: [],
      // 暂存商品规格的图片，用于展示
      specImgList: [],
      // 弹窗
      visible: false,
      // 规格
      specifications: [],
      // 兑换方式
      exchangeMethodOptions: [
        {
          label: '组合支付（积分+现金）',
          value: 1
        },
        {
          label: '仅积分',
          value: 0
        }
      ],
      // 所有商户信息
      merchantsOptions: [],
      // 规格明细list
      specificationDatailsList: [],
      // 当前券活动的总库存
      currentCouponTotal: 0,
      editor: null,
      userDescriptionEditor: null,
      // 多选状态
      indeterminate: false,
      IMG_API: IMG_API,
      // 是否可预览
      previewVisible: false,
      // 是否可预览
      previewCoverPhotoVisible: false,
      // 预览图片
      previewImage: "",
      // 封面图片预览
      previewCoverPhoto: '',
      columns: [
        {
          title: '优先级',
          dataIndex: 'priority',
          key: 'priority',
          customRender: (text, record, index) => {
            return index + 1
          }
        },
        {
          title: "仅积分（积分）",
          dataIndex: "integralValue",
          key: "integralValue",
        },
        {
          title: "组合支付（积分+现金）",
          dataIndex: "showCashFee",
          key: "showCashFee",
        },
        {
          title: "关联商户编号",
          dataIndex: "merchantId",
          key: "merchantId",
        },
        {
          title: "原价（元）",
          dataIndex: "productPrice",
          key: "productPrice",
        },
        {
          title: "库存",
          dataIndex: "sumStock",
          key: "sumStock",
        },
        {
          title: "规格图片",
          dataIndex: "specImg",
          key: "specImg",
          scopedSlots: { customRender: 'specImg' }
        },
        {
          title: "是否在售",
          dataIndex: "specSale",
          key: "specSale",
          customRender(text) {
            return text ? '是' : '否'
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: 'operation' },
        },
        {
          title: "已售数量",
          dataIndex: "marketNumber",
          key: "marketNumber",
        }
      ],
      pickAddressList: [
        {
          pickAddressFirstLevel: "",
          pickAddressSecondLevel: ""
        }
      ],
      form: {
        productType: "0", // 商品类型
        productName: "", // 商品名称
        productLabel: "", // 商品标签
        productCoverImg: '', // 商品封面图
        specImg: [], // 商品图片
        distributionType: 0, // 配送类型(0:自提，1:配送)
        pickAddressList: [], // 自提地区
        specType: 0, // 规格类型 0: 单规格，1：多规格
        productIntegral: "", // 商品积分价
        settlePrice: "", // 结算单价
        productUnit: "1", // 计量单位数量
        productDetails: "", // 商品详情
        projectList: [], // 归属项目
        floors: [''], // 关联楼层
        activeId: '', // 羊毛券
        needSmsValid: 0, // 是否短信验证
        specs: [], // 规格list,
        useLocation: '', // 使用地点
        useDescription: '' // 使用说明
      },
      rules: {
        // 商品名称
        productName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          { max: 50, message: "商品名称长度不能超过50个字符", trigger: "blur" }
        ],
        // 商品标签
        productLabel: [
          { max: 6, message: "商品标签长度不能超过6个字符", trigger: "blur" }
        ],
        // 封面图片
        productCoverImg: [
          { required: true, message: '请上传封面图片', trigger: 'change' }
        ],
        // 券活动ID
        activeId: [
          { required: true, message: "请填写对应的活动ID", trigger: ["blur", 'change'] }
        ],
        // 商品类型
        productType: [
          { required: true, message: "请选择商品类型", trigger: "change" }
        ],
        // 自提地址
        pickAddressList: [
          { validator: validatePickAddressList, trigger: "blur" }
        ],
        // 规格类型
        specType: [
          { required: true, message: '请选择规格类型', trigger: 'change' }
        ],
        // 单规格库存
        sumStock: [
          { required: true, message: '请输入库存', trigger: 'blur' },
        ],
        // 关联的商户id
        merchantId: [
          { required: true, message: '请选择关联商户编号', trigger: 'change' }
        ],
        // 归属项目
        projectList: [
          { validator: validateProjectList, trigger: "change" }
        ],
        // 商品积分价
        productIntegral: [
          { required: true, message: "请输入积分商品价", trigger: "blur" },
        ],
        // 计量单位数量
        productUnit: [
          { required: true, message: "请输入计量单位数量", trigger: "blur" },
        ],
        // 结算单价
        settlePrice: [
          { required: false, message: "请输入总库存数量", trigger: "blur" },
        ],
        // 是否短信验证
        needSmsValid: [
          { required: true, message: "请选择是否短信验证", trigger: "change" }
        ]
      },
      // 规格form
      specForm: {
        productPrice: '', // 商品原价
        sumStock: '', // 库存
        specImg: [], // 规格图片
        merchantId: undefined, // 关联商户id
        payTypes: [], // 兑换方式
        integralValue: '', // 仅积分的积分值
        integralFee: '', // 组合支付的积分值
        cashFee: '', // 组合支付的现金值
      },
      // 规格校验
      specFormRules: {
        // 单规格原价
        productPrice: [
          { required: true, message: '请输入原价', trigger: 'blur' }
        ],
        // 总库存(库存总量)
        sumStock: [
          { required: true, message: "请输入总库存数量", trigger: "blur" },
        ],
        // 商品图片
        specImg: [
          { required: true, message: "请上传商品图片", trigger: 'change' }
        ],
        // 兑换方式
        payTypes: [
          { required: true, message: '请选择兑换方式', trigger: 'change' }
        ],
        // 仅积分的积分值
        integralValue: [
          { required: true, message: '请输入所需积分值', trigger: 'blur' },
        ],
        // 组合支付商户
        merchantId: [
          { required: true, message: '请选择关联商户', trigger: 'change' }
        ],
        // 组合支付的积分值
        integralFee: [
          { required: true, message: '请输入所需积分值', trigger: 'blur' },
        ],
        // 组合支付的现金值
        cashFee: [
          { required: true, message: '请输入所需现金值', trigger: 'blur'},
        ],
        // 第三方串码的适用说明
        useDescription: [
          { validator: validateUseDescription, trigger: 'change'}
        ]
      },
      checkedList: [],
      checkAll: false,
      address: "",
      floorsList: [],
      // 商品类型选项
      productTypeOptions: [],
      // 羊毛券
      activeIdData: {}
    };
  },
  async created () {
    const type = this.$route.query.type
    this.disabled = type === 'view'
    this.operationType = type
    if(type !== 'view') {
      this.columns.splice(this.columns.length - 1, 1)
    }
    if (type === 'view' || type === 'edit') {
        this.currentProductId = this.$route.query.id
        await this.getDetail()
    }
    // this.loadGetProjectList()
    // 获取所有商户
    await this.getAllMerchant()
    await this.loadFloorList()
    await this.loadProductList('product_type')
    // 查看是否需要粘贴
    this.toPaste();
  },
  mounted () {
    const _this = this
    const editor = new wangEditor(`#editer`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.productDetails = newHtml
    }
    editor.config.menus = [
      'fontName',
      'fontSize',
      'foreColor',
      'backColor',
      'underline',
      'italic',
      'bold',
      'justify',
      'splitLine',
      'undo',
      'redo',
      'list',
      'table',
      'image',
      'video',
    ]
    editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgShowBase64 = false
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = 'file'
    editor.config.uploadVideoName = 'file'
    editor.config.uploadVideoTimeout = 60000
    editor.config.uploadImgTimeout = 60000
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function(xhr, editor, files) {
        console.log(xhr)
        console.log(editor)
        console.log(files)
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do('insertHTML', videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function(xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case 'success':
          _this.$message.success(s)
          break
        case 'info':
          _this.$message.info(s)
          break
        case 'warning':
          _this.$message.warning(s)
          break
        case 'error':
          _this.$message.error(s)
          break
        default:
          _this.$message.info(s)
          break
      }
    }
    editor.config.zIndex = 1;
    editor.config.placeholder = '请输入商品详情'
    // 创建编辑器
    editor.create()
    this.editor = editor
  },
  methods: {
    /**
     * 获取详情
     */
    async getDetail() {
      const res = await productDetail(this.currentProductId);
      const { code, data, msg } = res
      if (code === '200') {
        // 回显归属项目
        this.checkedList = data.productProject.map(item => { return item.projectId })
        // 回显自提地址
        this.pickAddressList = data.productSite
        // 回显楼层
        data.floors = data.floorProductList && data.floorProductList.length && data.floorProductList.map(item => { return item.floorId }) || ['']
        // 如果是羊毛券
        if (data.productType === 1) {
          data.activeId = data.specs[0].activeId
          await this.blurActiveId(data.activeId)
        }
        this.form = { ...data, productType: data.productType.toString() }
        // 回显商品封面图
        this.productCoverPhone = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: data.productCoverImg || ''
        }]
        // 第三方串码
        if (data.productType === 2) {
          this.codeList = data.thirdCodes
        }
        // 回显商品详情
        this.editor.txt.html(data.productDetails);
        if (this.disabled) { this.editor.disable() }
        // 回显规格
        if (data.specType === 0) {
          this.specForm = data.specs[0]
          this.specImgList = data.specs[0].specImg.map((imgItem, index) => {
            return {
              uid: index,
              name: 'image.png',
              status: 'done',
              url: imgItem,
            }
          })
          data.specs[0].specFees && data.specs[0].specFees.length && data.specs[0].specFees.forEach(item => {
            let obj = {}
            if (item.payType === '0') {
              obj.integralValue = item.integralFee
            } else {
              obj.integralFee = item.integralFee
              obj = item.merchantId
              obj = item.cashFee
            }
            this.specForm = {
              ...this.specForm,
              ...item,
              ...obj
            }
          })
        }
        // 回显多规格
        if (data.specType === 1) {
          let specsArr = []
          data.productSpecs && data.productSpecs.length && data.productSpecs.map(item => {
            const key = Object.keys(item)[0]
            specsArr.push({ specificationsName: key, specificationsValueList: item[key] })
          })
          this.specifications = specsArr
          // 回显多规格规格明细
          const keys = data.productSpecs && data.productSpecs.map(arr => {
            return Object.keys(arr)
          })
          keys && keys.length && keys.forEach((arr, index) => {
            this.columns.splice(index + 1, 0,
              {
              dataIndex: index === 0 ? 'firstSpecifications' : index === 1 ? 'secondSpecifications' : 'thirdSpecifications',
              title: arr
              }
            )
          })
          this.specificationDatailsList = data.specs && data.specs.length && data.specs.map(item => {
            // 组合支付的obj
            const specObj = item.specFees && item.specFees.length && item.specFees.find(item => item.payType === '1') || ''
            // 单规格
            const specObjOne= item.specFees && item.specFees.length && item.specFees.find(item => item.payType === '0') || ''
            return {
              ...item,
              firstSpecifications: item.specDesc[keys[0]],
              secondSpecifications: item.specDesc[keys[1]],
              thirdSpecifications: item.specDesc[keys[2]],
              integralValue: specObjOne.integralFee || '',
              showCashFee: specObj && specObj.integralFee &&  specObj.cashFee && `${ specObj.integralFee }积分 + ${ specObj.cashFee }元` || '',
              merchantId: specObj && specObj.merchantId || '',
              cashFee: specObj && specObj.cashFee || '',
              integralFee: specObj && specObj.integralFee || ''
            }
          })
        }
      } else {
        this.$message.error(msg)
      }
    },
    floorsChange (evt, index) {
      const idx = this.form.floors.findIndex((item, i) => item === evt && i !== index)
      if (idx !== -1) {
        this.form.floors[index] = ''
        this.$message.warning('请勿添加重复楼层')
      }
    },
    async blurActiveId (activeId) {
      if (!activeId) return
      const { data, code, msg } = await getActive(activeId)
      if (code === '200') {
        this.form.sumStock = data.sumStock
        // 当前活动券的总量
        this.currentCouponTotal = data.sumStock;
        delete data.sumStock
        this.activeIdData = data
      } else {
        this.activeIdData = {}
        this.$message.warning(msg)
      }
    },
    // 配送地址 blur 事件
    blurPickAddressList () {
      this.pickAddressList.some(item => {
        if (item?.pickAddressFirstLevel
          && item?.pickAddressSecondLevel) {
          this.$refs.ruleForm.clearValidate('pickAddressList')
        } else {
          this.$refs.ruleForm.validateField('pickAddressList')
          return true
        }
      })
    },
    // 类型change
    handleType ({ target }) {
      this.$refs.ruleForm.clearValidate('activeId')
      const val = target.value;
      if(val === '0') {
        this.pickAddressList.length = 0
        this.pickAddressList.push({
          pickAddressFirstLevel: "",
          pickAddressSecondLevel: ""
        })
      }
      if (val !== 0) {
        this.form.specType = 0
      }
      if (target.value === '1') {
        document.querySelectorAll(".ant-form-explain").forEach(item => {
          if (item.innerHTML === '请填写对应的活动ID') {
            item.style.display = 'none';
          }
        })
      } else {
        document.querySelectorAll(".ant-form-explain").forEach(item => {
          if (item.innerHTML === '请填写对应的活动ID') {
            item.style.display = 'block';
          }
        })
      }
    },
    // 获取归属项目
    async loadGetProjectList () {
      const res = await projectName()
      this.plainOptions = res.data.items.map(item => {
        return ({
          value: item.id,
          label: item.project_name
        })
      })
    },
    // 获取商户信息
    async getAllMerchant() {
      const res = await getMerchants()
      const { code, data, msg } = res
      if (code === '200') {
        this.merchantsOptions = data.map(item => {
          return {
            label: `${item.merchantId} - ${item.merchantName}`,
            value: item.merchantId
          }
        })
      } else {
        this.$message.error(msg)
      }
    },
    // 获取楼层项目
    async loadFloorList () {
      const { data } = await getFloorList()
      this.floorsList = data
    },
    // 获取商品类型项目
    async loadProductList (id) {
      const res = await getProductList(id)
      this.productTypeOptions = res.data
    },
    // 返回
    goback () {
      this.$router.go(-1);
    },
    // 添加地区
    addRegion () {
      this.pickAddressList.push({
        pickAddressFirstLevel: "",
        pickAddressSecondLevel: ""
      });
    },
    delRegion (index) {
      this.pickAddressList.splice(index, 1)
      this.blurPickAddressList()
    },
    // 添加楼层
    addFloor () {
      if (this.form.floors[this.form.floors?.length - 1]) {
        this.form.floors.push(undefined)
      } else {
        this.$message.warning('请先选择楼层再添加')
      }
    },
    // 删除楼层
    deleteFloor (index) {
      this.form.floors.splice(index, 1)
    },
    // 处理规格费用信息
    processFeeInformation(data) {
      const newSpecForm = JSON.parse(JSON.stringify(data))
      let specArr = []
      if (newSpecForm.payTypes.includes(0)) {
        specArr.push({ payType: 0, integralFee: newSpecForm.integralValue })
      }
      if (newSpecForm.payTypes.includes(1)) {
        specArr.push({ payType: 1, integralFee: newSpecForm.integralFee, merchantId: newSpecForm.merchantId, cashFee: newSpecForm.cashFee })
      }
      return specArr;
    },
    // 处理商品规格信息
    processSpecInformation(data) {
      const newSpecForm = JSON.parse(JSON.stringify(data))
      let arr = {}
      // 多规格商品
      if (this.form.specType === 1) {
        this.specifications.forEach((item, index) => {
          const key = item.specificationsName;
          if (index === 0) {
            arr = { ...arr, [key]: newSpecForm.firstSpecifications }
          }
          if (index === 1) {
            arr = { ...arr, [key]: newSpecForm.secondSpecifications }
          }
          if (index === 2) {
            arr = { ...arr, [key]: newSpecForm.thirdSpecifications }
          }
        })
      }
      return arr;
    },
    // 提交
    async save () {
      // 添加商品规格
      if (this.form.specType === 0) {
        this.form.specs = [ this.specForm ]
      }
      try {
        // 规格类型
        if (this.form.specType === 0) {
          this.form.specs = [ this.specForm ]
          // 如果是羊毛券，要把羊毛券数据set进去
          if(this.form.productType === '1') {
            // 券id
            this.form.specs[0].activeId = this.form.activeId
            // 券开始时间
            this.form.specs[0].couponStartAt = this.activeIdData.couponStartAt
            // 券过期时间
            this.form.specs[0].couponExpireAt = this.activeIdData.couponExpireAt
            // 券的限购数量
            this.form.specs[0].activeQuantity = this.activeIdData.activeQuantity
            // 券类型，0: 线下， 1：线上
            this.form.specs[0].activeType = this.activeIdData.activeType
            // 券使用时间类型，true 固定日期 false 指定时间段
            this.form.specs[0].activeDateType = this.activeIdData.activeDateType
          }
          // 如果是第三方串码
          if(this.form.productType === '2') {
            // 库存
            this.form.specs[0].sumStock = this.codeList.length
            // 第三方串码
            this.form.thirdCodes = this.codeList
          }
        } else {
          this.form.specs = this.specificationDatailsList
          this.form.productSpecs = this.specifications && this.specifications.length && this.specifications.map(item => {
            const key = item.specificationsName
            return {
              [key]: [ ...item.specificationsValueList ]
            }
          })
          this.form.specs.forEach(item => {
            console.log(item, '这是item')
            console.log(!item.integralValue && !item.specImg && !item.sumStock, '这是')
            if(!item.integralValue && !item.specImg && !item.sumStock ) {
              this.$message.warning('请完善规则明细！')
              throw new Error()
            }
          })
        }
        // 处理规格
        this.form.specs = this.form.specs.map(item => {
          return {
            ...item,
            fees: this.processFeeInformation(item),
            specDesc: this.processSpecInformation(item)
          }
        })
        // 暂时先写一下校验
        if(!this.checkData()) {
          throw new Error()
        }
        if (this.form.productType === '1' && !this.form.activeId) {
          this.$refs.activeId.focus()
          this.$refs.activeId.blur()
        }
        this.pickAddressList && this.pickAddressList.length && this.pickAddressList.some(item => {
          if ((item.pickAddressSecondLevel !== ''
              || item.pickAddressFirstLevel !== '')
            && this.form.productType !== '1'
          ) {
            this.form.pickAddressList = this.pickAddressList
            this.$refs.ruleForm.clearValidate('pickAddressList')
          } else {
            this.form.pickAddressList = []
            return true
          }
        })
        this.form.projectList = this.plainOptions.filter(item => {
          return this.checkedList.includes(item.value)
        }).map(item => {
          return {
            projectId: item.value,
            projectName: item.label
          }
        })
        if(this.form.projectList && this.form.projectList.length) {
          this.$refs.ruleForm.clearValidate('projectList')
        }
        if (this.form.productType === '1') {
          if (JSON.stringify(this.activeIdData) === '{}') {
            this.$message.warn('券活动ID无效！')
            return;
          }
          if (this.form.sumStock > this.currentCouponTotal) {
            this.$message.warn('羊毛券库存不能大于创建时的库存！')
            return;
          }

        }
        let obj = JSON.parse(JSON.stringify(this.form))
        if (obj.productType === '1') {
          delete obj.distributionType
          delete obj.pickAddressList
          obj = Object.assign(obj, this.activeIdData)
        } else {
          delete obj.activeId
        }
        // obj.specImg = this.form.specImg.map(item => item.response ? item.response.redirect_uri : item.url)
        const valid = await this.$refs.ruleForm.validate().catch(e => e);
        const specsValid = await this.$refs.specForm.validate().catch(e => e);
        if (!valid || !specsValid) {
          return false
        }
        if (valid) {
          if (obj.productType === '1' && !obj.activeId) {
            return
          }
          let res = {}
          if (this.currentProductId) {
            obj.id = this.currentProductId
            res = await updateProduct(obj)
          } else {
            res = await addProduct(obj)
          }
          const { code, msg } = res
          if (code === '200') {
            this.goback()
            this.$message.success(msg)
          } else {
            this.$message.error(msg)
          }
        }
      } catch (e) {
          console.log(e, '这是error')
      }
    },
    /**
     * 校验数据
     */
    checkData() {
      try {
        // 如果是第三方串码
        if(this.form.productType === '2') {
          if (!this.codeList.length) {
            this.warningHandle('请上传第三方串码！')
            throw new Error()
          }
          if (this.codeList.findIndex(item => !item.code || !item.startTime || !item.endTime ) >= 0) {
            this.warningHandle('请完善第三方串码数据！')
            throw new Error()
          }
          console.log(this.codeList, '###')
          if(this.hasDuplicate(this.codeList,'code')) {
            this.$message.warning('有重复的串码')
            throw new Error()
          }
          const i = this.codeList.findIndex(item => item.startTime > item.endTime)
          if ( i >=0 ) {
            this.warningHandle(`编号为${i + 1}的第三方串码开始时间不能小于结束时间！`)
            throw new Error()
          }
        }
        if(this.form.specType === 0) {
          return true;
        } else {
          // 判断是否添加了规格项
          if(this.specifications.length === 0) {
            this.warningHandle('请添加规格项！')
            throw new Error()
          }
          // 判断每个规格项的名称是否填写
          if(this.specifications.some(item => !(item.specificationsName.trim()))) {
            this.warningHandle('请完善规格项内容！')
            throw new Error()
          }
          // 判断每个规格项的规格值是否填写
          try {
            this.specifications.forEach(item => {
              if(!item.specificationsValueList.length) {
                this.warningHandle('请完善规格项内容！')
                throw new Error()
              }
              const flag = item.specificationsValueList.some(arr => !arr.trim())
              if(flag) {
                this.warningHandle('请完善规格项内容！')
                throw new Error()
              }
            })
            const flag = this.specificationDatailsList.some(item => !item.sumStock || item.sumStock === 0)
            if(flag) {
              this.warningHandle('请完善规格明细的库存！')
              throw new Error()
            }
          } catch (e) {
            throw new Error()
          }
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    /**
     *  警告处理
     */
    warningHandle(msg) {
      this.$message.warn(msg)
    },
    // 上传图片前校验
    beforeUpload (file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/png"
        || file.type === "image/jpg"
        || file.type === "image/gif"
        || file.type === 'image/jpeg';
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、gif或png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if(!isLt1M) {
        this.$message.error("图片最大不能超过1MB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt1M;
    },
    // 关闭图片图层
    handleCancel () {
      this.previewVisible = false;
    },
    // 预览图片
    handlePreview (file) {
      this.previewImage = file.url ? file.url : file.response.redirect_uri;
      this.previewVisible = true;
    },
    // 上传图片
    handleChange ({ file, fileList }) {
      console.log(fileList, 'file')
      this.specImgList = fileList
      if (fileList.length && fileList[0].status === 'done') {
        let imgList = []
        console.log(fileList, '这是fileList')
        fileList.forEach(item => {
          imgList.push(item.url ? item.url : item.response.redirect_uri)
        })
        this.specForm.specImg = imgList
      }
      if(!fileList.length) {
        this.specForm.specImg = []
      }
    },
    /**
     * 预览封面图片
     */
    handlePreviewCoverPhoto(file) {
      this.previewCoverPhoto = file.url ? file.url : file.response.redirect_uri
      this.previewCoverPhotoVisible = true
    },
    /**
     * 关闭封面浏览图层
     */
    handleCancelCoverPhotoPreview() {
      this.previewCoverPhotoVisible = false
    },
    // 关闭浏览封面图

    /**
     * 封面图片的校验
     */
    beforeCoverPhotoUpload(file) {
      console.log(file.type, 'type')
      // 校验格式
      const isJpgOrPng = file.type === "image/png"
          || file.type === "image/jpg"
          || file.type === 'image/jpeg'
          || file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、gif或png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if(!isLt1M) {
        this.$message.error("图片最大不能超过1MB");
        return Promise.reject();
      }
      return isJpgOrPng && isLt1M;
    },
    // 上传封面图片
    handleCoverPhotoChange({ file, fileList }) {
      this.productCoverPhone = fileList
      if (fileList.length && fileList[0].status === 'done') {
          this.form.productCoverImg = fileList[0].response.redirect_uri
      }
      if (!fileList.length) {
        this.form.productCoverImg = ''
        this.productCoverPhone = []
      }
    },
    // 全选项目
    onCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.plainOptions.map(item => item.value) : [],
        checkAll: e.target.checked,
        indeterminate: false
      });
      this.form.projectList = this.plainOptions.filter(item => {
        return this.checkedList.includes(item.value)
      }).map(item => {
        return {
          projectId: item.value,
          projectName: item.label
        }
      })
    },
    // 多选
    onChange (checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
      this.checkAll = checkedList.length === this.plainOptions.length;
      if (checkedList.length > 0) {
        this.$refs.ruleForm.clearValidate('projectList')
      } else {
        this.$refs.ruleForm.validateField('projectList')
      }
    },
    // 粘贴
    toPaste() {
      if (this.operationType !== 'add') return
      let that = this
      let productDate =  sessionStorage.getItem("productData");
      if (productDate) {
        this.$confirm({
          content: h => <div>已识别出复制的相关数据，是否展示复制内容</div>,
          onOk() {
            that.pasteData(JSON.parse(productDate));
          },
          onCancel() {
            sessionStorage.removeItem("productData")
          },
          class: 'test',
        });
      }
    },
    // 粘贴数据
    pasteData(data) {
      console.log(data)
      // 商品类型
      this.form.productType = data.productType.toString();
      //商品名称
      this.form.productName = data.productName;
      // 商品标签
      this.form.productLabel = data.productLabel;
      // 封面图片
      this.form.productCoverImg = data.productCoverImg;
      // 回显商品封面图
      this.productCoverPhone = [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: data.productCoverImg || ''
      }]
      // 是否短信验证
      this.form.needSmsValid = data.needSmsValid;
      // 配送类型
      this.form.distributionType = parseInt(data.distributionType === null ? 0 : data.distributionType);
      // 回显自提地址
      this.pickAddressList = data.productSite
      // 规格类型
      this.form.specType = data.specType
      // 积分详情
      this.form.productDetails = data.productDetails;
      // 关联楼层
      this.form.floors = this.convertFloorsData(data.floorProductList);
      // 使用地点
      this.form.useLocation = data.useLocation
      // 使用说明
        this.form.useDescription = data.useDescription
      // 多规格设置
      if(data.specType === 1) {
        let specsArr = []
        data.productSpecs && data.productSpecs.length && data.productSpecs.map(item => {
          const key = Object.keys(item)[0]
          specsArr.push({ specificationsName: key, specificationsValueList: item[key] })
        })
        this.specifications = specsArr
        // 回显多规格规格明细
        const keys = data.productSpecs && data.productSpecs.map(arr => {
          return Object.keys(arr)
        })
        keys && keys.length && keys.forEach((arr, index) => {
          this.columns.splice(index + 1, 0,
            {
              dataIndex: index === 0 ? 'firstSpecifications' : index === 1 ? 'secondSpecifications' : 'thirdSpecifications',
              title: arr
            }
          )
        })
        this.specificationDatailsList = data.specs && data.specs.length && data.specs.map(item => {
          item.sumStock = ''
          item.productId = ''
          item.specId = ''
          // 组合支付的obj
          const specObj = item.specFees && item.specFees.length && item.specFees.find(item => item.payType === '1') || ''
          // 单规格
          const specObjOne= item.specFees && item.specFees.length && item.specFees.find(item => item.payType === '0') || ''
          return {
            ...item,
            firstSpecifications: item.specDesc[keys[0]],
            secondSpecifications: item.specDesc[keys[1]],
            thirdSpecifications: item.specDesc[keys[2]],
            integralValue: specObjOne.integralFee || '',
            showCashFee: specObj && specObj.integralFee &&  specObj.cashFee && `${ specObj.integralFee }积分 + ${ specObj.cashFee }元` || '',
            merchantId: specObj && specObj.merchantId || '',
            cashFee: specObj && specObj.cashFee || '',
            integralFee: specObj && specObj.integralFee || ''
          }
        })
      }
      // 单规格
      if(data.specType === 0) {
        const specsObj = data.specs && data.specs.length && data.specs[0]
        if(specsObj) {
          // 原价
          this.specForm.productPrice = specsObj.productPrice
          // 支付类型
          this.specForm.payTypes = specsObj.payTypes
          // 单规格图片
          this.specForm.specImg = specsObj.specImg
          this.specImgList = specsObj.specImg.map((imgItem, index) => {
            return {
              uid: index,
              name: 'image.png',
              status: 'done',
              url: imgItem,
            }
          })
          specsObj.specFees && specsObj.specFees.length && specsObj.specFees.forEach(item => {
            // 仅积分
            if(item.payType === '0') {
              this.specForm.integralValue = item.integralFee
            }
            // 组合支付
            if (item.payType === '1') {
              this.specForm.integralFee = item.integralFee
              this.specForm.merchantId = item.merchantId
              this.specForm.cashFee = item.cashFee
            }
          })
        }
      }

      // 商品详情
      this.form.productDetails = data.productDetails;
      this.editor.txt.html(this.form.productDetails);
      // 关联楼层
      this.form.floors = this.convertFloorsData(data.floorProductList);
      // 清空sessionStorage
      sessionStorage.removeItem("productData")
    },
    // 商品图片转化
    // convertProductImg(data) {
    //   let productImgList = data.map(item => {
    //     return {
    //       uid: item.fileId,
    //       url: item.imgUrl,
    //       name: item.fileId
    //     }
    //   })
    //   return productImgList;
    // },
    // 自提地址转化
    convertPickAddressList(data) {
      let newData = [{
          pickAddressFirstLevel: "",
          pickAddressSecondLevel: ""
      }];
      if (data.length > 0) {
        newData= data.map(item => {
          return {
            pickAddressFirstLevel: item.pickAddressFirstLevel,
            pickAddressSecondLevel: item.pickAddressSecondLevel
          }
        })
      } else {
        this.pickAddressList = newData;
      }
      return newData;
    },
    // 关联楼层的数据转化
    convertFloorsData(data) {
      return data.map(item => item.floorId)
    },
    /**
     * 添加规格
     */
    addSpecifications() {
      if(this.specifications.length === 3) {
        this.$message.warn('规格项最多添加3个')
        return false
      }
      this.specifications.push(
        {
          specificationsName: '',
          specificationsValueList: ['']
        }
      )
    },
    /**
     * 删除规格项
     * @params index：要删除的规格项
     */
    removeSpecifications(index) {
      const newData = JSON.parse(JSON.stringify(this.specifications))
      newData.splice(index, 1)
      this.specifications = newData
      this.columns.splice(index + 1, 1)
      this.handleChangeSpecificationsValue()
    },
    /**
     * 规格名称change事件
     */
    handleChangeSpecificationsName(e, index) {
      // 当前操作的规格名称对应的在columns中的下标
      const currentColum = this.columns[index + 1];
      // 如果当前column是自定义的，则修改title名称，否则添加新的title
      if(currentColum.dataIndex === 'firstSpecifications' || currentColum.dataIndex === 'secondSpecifications' || currentColum.dataIndex === 'thirdSpecifications') {
        this.columns[index + 1].title = e.target.value;
      } else {
        this.columns.splice( index + 1, 0, {
          title: e.target.value,
          dataIndex: index === 0 ? 'firstSpecifications' : index === 1 ? 'secondSpecifications' : 'thirdSpecifications',
        })
      }
    },
    /**
     * 规格值change事件
     * @params e:当前事件对象， index：当前操作的规格项下标，i：当前操作的规格值下标
     */
    handleChangeSpecificationsValue(e, index, i) {
      // 当前输入的值
      // const val = e.target.value
      // 获取组合后的商品sku
      const skuList = this.generateSkuList()
      console.log(skuList)
      const newData = skuList.map(item => {
        return {
          firstSpecifications: item[0],
          secondSpecifications: item[1],
          thirdSpecifications: item[2]
        }
      })
      this.specificationDatailsList = newData;
    },
    /**
     * 生成所有组合的规格
     */
    generateSkuList() {
      const result = []
      const temp = []
      const length = this.specifications.length
      const dfs = (index) => {
        if (index === length) {
          result.push([...temp])
          return
        }
        const { specificationsName, specificationsValueList } = this.specifications[index]
        for (let i = 0; i < specificationsValueList.length; i++) {
          temp[index] = specificationsValueList[i]
          dfs(index + 1)
        }
      }
      dfs(0)
      return result
    },
    /**
     * 添加规格值
     * params: index: 要添加的规格下标
     */
    addSpecificationsValue(index) {
      // 判断每个规格项下最多5个规格值
      if(this.specifications[index].specificationsValueList.length === 5) {
        this.$message.warn('每个规格项下最多5个规格值')
        return false
      }
      const newData = JSON.parse(JSON.stringify(this.specifications))
      newData[index].specificationsValueList.push('')
      this.specifications = newData
      this.handleChangeSpecificationsValue()
    },
    /**
     * 删除规格项下的规格值
     * @params index：要删除的规格下标，i：要删除的规格项下的规格值下标
     */
    removeSpecificationsValue(index, i) {
      const newData = JSON.parse(JSON.stringify(this.specifications))
      newData[index].specificationsValueList.splice(i, 1)
      this.specifications = newData
      this.handleChangeSpecificationsValue()
    },
    /**
     * 兑换规则设置
     */
    exchangeRuleSettings(data, index) {
      this.visible = true
      this.currentEditSpecIndex = index
      this.currentEditSpecData = data
    },
    /**
     * 关闭弹窗
     */
    closeModal() {
      this.visible = false
    },
    /**
     * 保存兑换规则
     */
    submitSpecConfig(data) {
      console.log(data, '这是从字组件传回来的数据')
      let newData = JSON.parse(JSON.stringify(data))
      if (newData.payTypes.includes(1)) {
        newData.showCashFee = `${newData.integralFee}积分 + ${newData.cashFee}元`
      }
      this.specificationDatailsList.splice(this.currentEditSpecIndex, 1, newData)
      this.closeModal()
    },
    // 下载模版
    downloadTemplate() {
      location.href = "https://oss-workplace-prod.innoecos.cn/picture/08a06303b9800000_%E4%B8%B2%E7%A0%81%E6%A8%A1%E6%9D%BF.xlsx";
    },
    // 上传excel并解析数据
    handleChangeFile(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // this.$message.success(`${info.file.name} 上传成功`);
        const { response: { code, msg, data } } = info.file
        if (code === '200') {
          let newData = JSON.parse(JSON.stringify(data))
          const newCodeList = JSON.parse(JSON.stringify(this.codeList))
          this.codeList = this.uniqueByCode([ ...newCodeList, ...newData ])
        } else {
          this.$message.warning(msg)
        }
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    // 处理重复的串码
    uniqueByCode(arr) {
      const seen = new Set();
      return arr.filter(item => {
        const value = item['code'];
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    },
    // 校验是否有重复的串码
    hasDuplicate(arr, key) {
      const values = new Set();
      for (const obj of arr) {
        const value = obj[key];
        if (values.has(value)) {
          return true;
        }
        values.add(value);
      }
      return false;
    },
    // 设置codeList
    setCodeData(data) {
      this.codeList = data
    },
    /**
     * 创建富文本
     */
    createEditor() {
      const _this = this
      const editor = new wangEditor(`#editor`)
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.useDescription = newHtml
      }
      editor.config.menus = [
        'fontName',
        'fontSize',
        'foreColor',
        'backColor',
        'underline',
        'italic',
        'bold',
        'justify',
        'splitLine',
        'undo',
        'redo',
        'list',
        'table',
        'image',
      ]
      editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgShowBase64 = false
      editor.config.showLinkImg = false
      editor.config.showLinkVideo = false
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = 'file'
      editor.config.uploadVideoName = 'file'
      editor.config.uploadVideoTimeout = 60000
      editor.config.uploadImgTimeout = 60000
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function(xhr, editor, files) {
          console.log(xhr)
          console.log(editor)
          console.log(files)
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do('insertHTML', videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function(xhr) {
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case 'success':
            _this.$message.success(s)
            break
          case 'info':
            _this.$message.info(s)
            break
          case 'warning':
            _this.$message.warning(s)
            break
          case 'error':
            _this.$message.error(s)
            break
          default:
            _this.$message.info(s)
            break
        }
      }
      editor.config.placeholder = '请输入使用说明'
      editor.config.zIndex = 1;
      // 创建编辑器
      editor.create()
      if (this.disabled) {
        editor.disable()
      }
      this.userDescriptionEditor = editor
      setTimeout(() => {
        this.userDescriptionEditor.txt.html(this.form.useDescription)
      },100);
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  },
};
</script>

<style scoped lang="scss">
.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

::v-deep .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

::v-deep .avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

::v-deep .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

::v-deep .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  /* margin-left:24px; */
  margin-top: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

::v-deep .ant-checkbox-group {
  width: 100%;
  .ant-checkbox-group-item {
    width: 18%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}

::v-deep .ant-select-dropdown {
  z-index: 99999;
}

::v-deep #editer {
  tr {
    height: 28.5px;
  }
}

::v-deep .userLabel .ant-form-item-label label {
  &:before {
    content: '*';
    color: red;
  }
}

::v-deep .ant-table-thead {
  th {
    &:before {
      content: '*';
      color: red;
    }
  }

  th:nth-child(5) {
    &:before {
      content: '';
    }
  }
  th:nth-child(6) {
    &:before {
      content: '';
    }
  }
}
</style>
